<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Add new role
      </h3>
      <div slot="content">
        <role-form @close="isModalVisible = false" @add-role="addRole" />
      </div>
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <OrganizationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-header">
                <h4>
                  Roles
                  <button
                    class="btn btn-sm float-right btn-outline-secondary m-1"
                    @click="isModalVisible = true"
                  >
                    Add role
                  </button>
                </h4>
              </div>
              <div v-if="Object.keys(roles).length !== 0" class="card-body">
                <data-table :headers="headers()" :data="roles">
                  <template #name="{ item }">
                    <div class="h4" :variant="item.name">
                      {{ item.name }}
                    </div>
                  </template>
                  <template #slug="{ item }">
                    <strong>{{ item.slug }}</strong>
                  </template>
                  <template #actions="{ item }">
                    <div>
                      <router-link
                        v-if="hasPermission('edit-roles')"
                        :to="{
                          name: 'organization.role',
                          params: { roleId: item.id },
                        }"
                        class="btn btn-sm btn-outline-secondary mr-3"
                      >
                        Edit
                      </router-link>
                      <DeleteButton
                        class="d-inline-block"
                        :uri="`organization/roles/${item.id}`"
                        :name="item.name"
                        :isSmall="true"
                        @deleted="getRoles"
                      />
                    </div>
                  </template>
                </data-table>
              </div>
              <div v-else class="card-body">
                <p class="text-muted">
                  This order list is empty: You're seeing this message either
                  because: 1. There are no relevant orders to show for the
                  filter 2. The plugin/api is not correctly connected 3.
                  Automation.app is (unlikely) down. To resolve this, try new
                  filtering options or contact
                  <a href="mailto:nerds@automation.app">nerds@automation.app</a
                  >.
                </p>
                <div class="p-4 rounded border-zip text-center">
                  <p class="mb-0">
                    No recent orders has been found!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import Prompt from "@/components/UI/Prompt.vue";
import RoleForm from "@/components/Form/RoleForm.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";

export default {
  name: "RolesList",
  components: {
    DataTable,
    Default,
    OrganizationNav,
    StatusBadge,
    Prompt,
    RoleForm,
    DeleteButton,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    let uri = "/organization/roles";
    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.roles = response.data;
      });
    });
  },
  data() {
    return {
      layout: "Default",
      isModalVisible: false,
      roles: {
        data: [],
        total: 0,
        links: {},
      },
      roleId: "",
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: false, sortkey: "name" },
        { title: "Slug", key: "slug", sortable: false, sortkey: "price" },
        { title: "Actions", key: "actions" },
      ];
    },
    getRoles: function(page = 1) {
      let uri = "/organization/roles?page=" + page;

      jsonAPI
        .get(uri)
        .then((response) => {
          this.roles = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    addRole(role) {
      role.organization_id = this.getOrganization.id;
      jsonAPI.post("organization/roles", role).then(() => {
        window.Bus.$emit("flash-message", {
          text: "Role has been added",
          type: "success",
        });
        this.getRoles();
      });
    },
  },
};
</script>
