var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('prompt',{attrs:{"active":_vm.isModalVisible},on:{"close":function($event){_vm.isModalVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Add new role ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('role-form',{on:{"close":function($event){_vm.isModalVisible = false},"add-role":_vm.addRole}})],1)]),_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('OrganizationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Roles "),_c('button',{staticClass:"btn btn-sm float-right btn-outline-secondary m-1",on:{"click":function($event){_vm.isModalVisible = true}}},[_vm._v(" Add role ")])])]),(Object.keys(_vm.roles).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"data":_vm.roles},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"h4",attrs:{"variant":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"slug",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.slug))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.hasPermission('edit-roles'))?_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary mr-3",attrs:{"to":{
                        name: 'organization.role',
                        params: { roleId: item.id },
                      }}},[_vm._v(" Edit ")]):_vm._e(),_c('DeleteButton',{staticClass:"d-inline-block",attrs:{"uri":("organization/roles/" + (item.id)),"name":item.name,"isSmall":true},on:{"deleted":_vm.getRoles}})],1)]}}],null,false,1449984196)})],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This order list is empty: You're seeing this message either because: 1. There are no relevant orders to show for the filter 2. The plugin/api is not correctly connected 3. Automation.app is (unlikely) down. To resolve this, try new filtering options or contact "),_c('a',{attrs:{"href":"mailto:nerds@automation.app"}},[_vm._v("nerds@automation.app")]),_vm._v(". ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No recent orders has been found! ")])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }